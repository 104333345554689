<template>
  <div>
    <v-dialog
      v-model="showDialog"
      max-width="600"
      scrollable
      @keydown="dialogKeyDown($event)"
    >
      <!-- Botão que vai pro cabeçalho -->
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>{{ icon }}</v-icon>
          {{ name }}
        </div>
      </template>

      <!-- Corpo do modal -->
      <v-card>
        <v-card-title>
          <v-icon x-large class="mr-3">{{ icon }}</v-icon> 
          {{ name }}
          <v-btn
            @click="showDialog = false"
            style="position: absolute; right: 5px; top: 5px"
            icon
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row align="center">
            <v-col :cols="4">
              <p class="mb-0">Selecione o ano base:</p>
            </v-col>
            <v-col v-if="showDialog" :cols="4">
              <year-select @change="getStatus($event)"></year-select>
            </v-col>
            <v-col
              v-if="status.length === 0"
              class="text-center"
              :cols="12"
            >
              <p>Nenhum status encontrado.</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-for="col of status" :key="col.month"
              :cols="4"
              :title="getTitle(col)"
            >
              <v-row>
                <v-col :cols="6">
                  {{ col.month | toMonth }}
                </v-col>
                <v-col :cols="6">
                  <v-progress-circular
                    v-if="col.isLoading"
                    color="primary"
                    indeterminate
                    :size="20"
                  ></v-progress-circular>
                  <v-icon
                    v-else
                    :color="col.isOpen ? 'green' : 'info'"
                    dense
                    @click="doExecute(col)"
                  > 
                    {{ col.isOpen ? 'mdi-lock-open' : 'mdi-lock-check' }} 
                  </v-icon>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6 pb-3 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            dark
            depressed
            @click="showDialog = false"
          >
            Fechar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    "year-select": () => import('@/components/year-select.vue'),
  },
  computed: {
    ...mapGetters(['clientId', 'servico']),
  },
  data: function () {
    return {
      showDialog: false,
      status: [],
    }
  },
  methods: {
    dialogKeyDown: function (keyEvent) {
      const { code } = keyEvent;
      if (code === "Escape") {
        this.showDialog = false;
      }
    },
    doExecute: function (status) {
      status.isLoading = true;
      const { month, isOpen } = status;
      let query = `competenciaIni=${month}&competenciaFim=${month}`;

      if (this.beneficio) {
        query += `&trabalho=${this.servico}`;
      }

      const url = `/v1/clientes/${this.clientId}/${isOpen ? 'fechar' : 'abrir'}/${this.type}?${query}`;
      this.apiResource(url)
        .silentSave()
        .then(() => status.isOpen = !isOpen)
        .finally(() => status.isLoading = false);
    },
    getStatus: function (anoBase) {
      let query = `anoBase=${anoBase}`;

      if (this.beneficio) {
        query += `&trabalho=${this.servico}`;
      }

      let url = `/v1/clientes/${this.clientId}/statusCalculo/${this.type}?${query}`;
      this.status = [];
      this.apiResource(url).get().then(response => {
        if (response.error) {
          return this.status = [];
        }

        this.status = Object
          .entries(response.competencia)
          .map(([month, isOpen]) => ({ month, isOpen: isOpen === 'aberto', isLoading: false }));
      });
    },
    getTitle: function (col) {
      if (col.isLoading) {
        return 'Carregando';
      }

      if (col.isOpen) {
        return 'Aberto';
      }

      return 'Fechado';
    },
  },
  props: {
    beneficio: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'mdi-clock-edit-outline',
    },
    name: {
      type: String,
      default: 'Status de Timesheets',
    },
    type: {
      type: String,
      default: 'timesheet',
    },
  },
}
</script>
